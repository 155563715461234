import { sprintf, vsprintf } from 'sprintf-js';
import TypeHelper from './helpers/type';

class I18n {
    constructor() {
        this.dictionary = {};
    }

    load(aDictionary) {
        Object.assign(this.dictionary, aDictionary);
    }

    translate(id, params) {
        let text;

        if (!id) {
            return '';
        }

        text = this.dictionary[id] ? this.dictionary[id] : id;

        if (!params) {
            return text;
        }

        if (TypeHelper.isString(params)) {
            return sprintf(text, params);
        }

        if (TypeHelper.isArray(params) && params.length > 0) {
            return vsprintf(text, params);
        }

        if (TypeHelper.isObject(params)) {
            Object.keys(params).forEach((key) => {
                text = text.replace(`%${key}`, params[key]);
            });

            return text;
        }

        return text;
    }
}

/* eslint-disable */
window.i18n = new I18n();
window._T = window.i18n.translate.bind(window.i18n);
/* eslint-enable */
