export default {
    isString(variable) {
        return (typeof variable === 'string' || variable instanceof String);
    },

    isArray(variable) {
        return Array.isArray(variable);
    },

    isObject(variable) {
        const type = typeof variable;

        return variable != null && (type === 'object' || type === 'function');
    }
};
